import React from 'react'
import Tennisjpg from '../images/kv/KV03.webp'
import TennisMatches from './TennisMatches';

export default function Tennis(props) {
  return (
    <div>

      <div>
        <div class="images-grid">
          <img src={Tennisjpg} alt="" />
        </div>
        <div id="gameHighlightWrap" style={{ marginBottom: '0' }} class="highlight-edge-content col3">
        <h3 style={{textAlign: 'left'}}>Sports Highlights<div class="highlight-sorting"><label for="lang">View by</label><div class="select"><select id="viewType" name="View"><option id="competitionName" value="competitionName" orderby="asc" priority="asc">Competition</option><option id="openDateTime" value="openDateTime" orderby="asc" inplay="desc">Time</option><option id="totalMatched" value="totalMatched" orderby="desc">Matched</option></select></div></div> </h3>
            <ul class="sports-header">
          <li class="col-game" style={{ width: 'calc(64% - -60px)' }}></li>
          <li class="sport-odds">1</li>
          <li class="col-draw">2</li>

          <li class="info-icon"></li>
        </ul>

        <TennisMatches changeState={props.changeState} />
        </div>
       
      </div>
    </div>
  )
}
