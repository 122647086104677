import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Transparent from '../images/sprite/transparent.gif'
import { toast } from 'react-toastify'


toast.configure()
export default function Settings(props) {
  //const [editStake, seteditStake]=useState(false);
  const [buttonStake, setbuttonStake] = useState(true);
  const [ssstake1, setSsstake1] = useState(0);
  const [ssstake2, setSsstake2] = useState(0);
  const [ssstake3, setSsstake3] = useState(0);
  const [ssstake4, setSsstake4] = useState(0);
  const [ssstake5, setSsstake5] = useState(0);
  const [ssstake6, setSsstake6] = useState(0);
  const [ssstake7, setSsstake7] = useState(0);
  const [ssstake8, setSsstake8] = useState(0);
  const [defaultStake, setdefaultStake] = useState('');


  useEffect(() => {

    if (!props.userid) return;
    axios.post('https://liveapi247.live/api8/ssgetclickstake', {

      id: props.userid,
      token: props.utoken,

    })
      .then(result => {
        if (result.status === 210) {


          setdefaultStake(result.data[0].defaultStake);
          setSsstake1(result.data[0].SSstake1);
          setSsstake2(result.data[0].SSstake2);
          setSsstake3(result.data[0].SSstake3);
          setSsstake4(result.data[0].SSstake4);
          setSsstake5(result.data[0].SSstake5);
          setSsstake6(result.data[0].SSstake6);
          setSsstake7(result.data[0].SSstake7);
          setSsstake8(result.data[0].SSstake8);

        }

      }

      ).catch(e => {
        //setIsError(true);
      });
  }, [])

  const handleEditClick = () => {
    // seteditStake(true);
    setbuttonStake(false);
  };

  const hadleSaveClick = (e) => {


    e.preventDefault();

    setbuttonStake(true);
  };

  const handleSaveClick = (e) => {


    e.preventDefault();

    setbuttonStake(true);


    axios.post('https://liveapi247.live/api8/sssaveclickstake', {
      id: props.userid,
      token: props.utoken,
      defaultStake: defaultStake,
      ssstake1: ssstake1,
      ssstake2: ssstake2,
      ssstake3: ssstake3,
      ssstake4: ssstake4,
      ssstake5: ssstake5,
      ssstake6: ssstake6,
      ssstake7: ssstake7,
      ssstake8: ssstake8,

    })
      .then(result => {

        if (result.status === 210) {
          toast.warn('Bet stake settings saved succefully', { position: toast.POSITION.TOP_CENTER });
          // console.log('Updated successful')

        }

        if (result.status === 220) {
          // console.log('saveclick unsucessful');


        }
      }

      ).catch(e => {
        //setIsError(true);
      });
  };
  const handleChange1 = (event) => {
    setSsstake1(event.target.value);
  };

  const handleChange2 = (event) => {
    setSsstake2(event.target.value);
  };
  const handleChange3 = (event) => {
    setSsstake3(event.target.value);
  };
  const handleChange4 = (event) => {
    setSsstake4(event.target.value);
  };
  const handleChange5 = (event) => {
    setSsstake5(event.target.value);
  };

  const handleChange6 = (event) => {
    setSsstake6(event.target.value);
  };
  const handleChange7 = (event) => {
    setSsstake7(event.target.value);
  };
  const handleChange8 = (event) => {
    setSsstake8(event.target.value);
  };
  const changeDefaultStake = (event) => {

    setdefaultStake(event.target.value);
  };

  return (
    <div>

      <div id="coinList" class="set-content">
        <dl class="odds-set">
          <dd class="col-defult">
            <label htmlFor="defult_stake"><strong>Default stake</strong></label>
            <input id="userCoin" class="stake-input" onChange={(e) => { changeDefaultStake(e); }} type="number" value={defaultStake} maxLength="7" />
          </dd>
        </dl>


        {buttonStake === true &&
          <dl _ngcontent-njs-c61 id="stakeSet" class="stake-set">
            <dt _ngcontent-njs-c61>Stake</dt>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_1" style={{ cursor: ' pointer' }} class="btn select">{ssstake1}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_2" style={{ cursor: ' pointer' }} class="btn select">{ssstake2}</a></dd>
            <dd><a _ngcontent-njs-c61 id="coin_3" style={{ cursor: ' pointer' }} class="btn select">{ssstake3}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_4" style={{ cursor: ' pointer' }} class="btn select">{ssstake4}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_5" style={{ cursor: ' pointer' }} class="btn select">{ssstake5}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_6" style={{ cursor: ' pointer' }} class="btn select">{ssstake6}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_7" style={{ cursor: ' pointer' }} class="btn select">{ssstake7}</a></dd>
            <dd _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="coin_8" style={{ cursor: ' pointer' }} class="btn select">{ssstake8}</a></dd>
            <dd _ngcontent-njs-c61 class="col-edit"><a id="edit" onClick={(e) => { handleEditClick(e); }} class="btn-edit">Edit <img src={Transparent} alt="gif" /></a></dd>
          </dl>}
        {buttonStake === false &&
          <dl _ngcontent-njs-c61 id="editCustomizeStakeList" class="stake-set">
            <dt _ngcontent-njs-c61  >Stake</dt>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_1" class="stake_edit-input" onChange={(e) => { handleChange1(e); }} type="text" value={ssstake1} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_2" class="stake_edit-input" onChange={(e) => { handleChange2(e); }} type="text" value={ssstake2} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_3" class="stake_edit-input" onChange={(e) => { handleChange3(e); }} type="text" value={ssstake3} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_4" class="stake_edit-input" onChange={(e) => { handleChange4(e); }} type="text" value={ssstake4} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_5" class="stake_edit-input" onChange={(e) => { handleChange5(e); }} type="text" value={ssstake5} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_6" class="stake_edit-input" onChange={(e) => { handleChange6(e); }} type="text" value={ssstake6} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_7" class="stake_edit-input" onChange={(e) => { handleChange7(e); }} type="text" value={ssstake7} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61  ><input _ngcontent-njs-c61 id="stakeEdit_8" class="stake_edit-input" onChange={(e) => { handleChange8(e); }} type="text" value={ssstake8} maxLength="7" /></dd>
            <dd _ngcontent-njs-c61 class="col-edit"><a _ngcontent-njs-c61 id="ok" onClick={(e) => { hadleSaveClick(e); }} class="btn-send">OK</a></dd>
          </dl>}


        <dl _ngcontent-njs-c61 class="odds-set">
          <dt _ngcontent-njs-c61  >Odds</dt>
          <dd _ngcontent-njs-c61  >

            <input _ngcontent-njs-c61 id="enableSparkCheck" type="checkbox" />
            <label _ngcontent-njs-c61 htmlFor="enableSparkCheck">Highlight when odds change</label>
          </dd>
        </dl>
        <dl _ngcontent-njs-c61 class="odds-set">
          <dt _ngcontent-njs-c61  >FancyBet</dt>
          <dd _ngcontent-njs-c61  >

            <input _ngcontent-njs-c61 id="enableSparkCheck" type="checkbox" />
            <label _ngcontent-njs-c61 htmlFor="enableSparkCheck">Accept Any Odds </label>
          </dd>
        </dl>
        <dl _ngcontent-njs-c61 class="odds-set">
          <dt _ngcontent-njs-c61  >SportsBook</dt>
          <dd _ngcontent-njs-c61  >

            <input _ngcontent-njs-c61 id="enableSparkCheck" type="checkbox" />
            <label _ngcontent-njs-c61 htmlFor="enableSparkCheck">Accept Any Odds </label>
          </dd>
        </dl>
        <dl _ngcontent-njs-c61 class="odds-set">
          <dt _ngcontent-njs-c61  >Binay</dt>
          <dd _ngcontent-njs-c61  >

            <input _ngcontent-njs-c61 id="enableSparkCheck" type="checkbox" />
            <label _ngcontent-njs-c61 htmlFor="enableSparkCheck">Accept Any Price </label>
          </dd>
        </dl>
        <ul _ngcontent-njs-c61 class="btn-wrap">
          <li _ngcontent-njs-c61  ><a _ngcontent-njs-c61 id="closeSet" class="btn" onClick={props.handlesetting} style={{ cursor: ' pointer' }}>Cancel</a></li>
          <li _ngcontent-njs-c61 class="col-send"><a _ngcontent-njs-c61 id="coinSave" class="btn-send" onClick={(e) => { handleSaveClick(e); props.handlesetting(); }} style={{ cursor: ' pointer' }}>Save</a></li>
        </ul>


      </div>

    </div>
  )
}

