import React, { memo } from 'react';


const LoadingOverlay = ({ show, style }) => {
    if (show) {
        return (
            <div className="loading-overlay" id="loading" style={{ zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.03)', ...style}}>
                <div className="loading-wrap" style={{ display: " flex" }}>
                    <div className="loading">
                        <div></div>
                        <div></div>
                    </div>
                    <p>Loading...</p>
                </div>
            </div>
        );
    }
    return null;
};

export default memo(LoadingOverlay);
