import React, { useState } from 'react'

import News from './News';

function Result() {
    const [tab, settab] = useState(1);
    return (
        <div _ngcontent-njs-c61 style={{ backgroundColor: 'rgb(239 236 225)' }}>
            <div _ngcontent-njs-c61 style={{ width: '1020px', marginLeft: '20px', float: 'left', backgroundColor: 'white', overflowY: 'scroll' }}>
                {/* <News /> */}
                <div _ngcontent-njs-c61 style={{ height: '50px' }}>
                    <div _ngcontent-njs-c61 class="result-search" style={{ marginTop: '10px' }}>
                        <div _ngcontent-njs-c61  >
                            <div _ngcontent-njs-c61 style={{ float: 'left' }} >
                                <ul _ngcontent-njs-c61 id="resultTab" class="tab col2">
                                    <li _ngcontent-njs-c61  ><a id="today" href="#Today" className={`${(tab == 1) ? "select" : "null"}`} onClick={() => { settab(1) }} >Today</a></li>
                                    <li _ngcontent-njs-c61  ><a id="yesterday" href="#Yesterday" className={`${(tab == 2) ? "select" : "null"}`} onClick={() => { settab(2) }} >Yesterday</a></li>
                                </ul>
                            </div>
                            <div _ngcontent-njs-c61 style={{ float: 'right' }}>
                                <select _ngcontent-njs-c61 id="sports" style={{ height: '30px', width: '200%', marginTop: '2px' }}>
                                    <option _ngcontent-njs-c61 value="4">CRICKET</option>
                                    <option value="1">SOCCER</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div _ngcontent-njs-c61 class="lit-content-center" style={{ height: 'calc(100% - 39px - 31px - 35px)' }}>
                        {tab == 1 &&
                            <table _ngcontent-njs-c61 id="Result_Table" class="table01">
                                <caption class="align-L" colspan="4">Result</caption>
                                <tbody><tr>
                                    <th _ngcontent-njs-c61 width="20%" class="align-L">Event Date/Time</th>
                                    <th _ngcontent-njs-c61 class="align-L">Event Name</th>
                                    <th _ngcontent-njs-c61 id="Result_Title_1" width="20%">Home</th>
                                    <th _ngcontent-njs-c61 id="Result_Title_2" width="20%">Away</th>
                                </tr>
                                </tbody><tbody _ngcontent-njs-c61 id="Result_Content"><tr id="noDataTemplate" style={{ display: "table-row" }}>
                                    <td _ngcontent-njs-c61 class="no-data" colspan="9">
                                        <p>There are no events to be displayed.</p>
                                    </td>
                                </tr></tbody>
                            </table>
                        }
                        {tab == 2 &&
                            <table _ngcontent-njs-c61 id="Result_Table" class="table01">
                                <caption class="align-L" colspan="4">Result</caption>
                                <tbody _ngcontent-njs-c61  ><tr _ngcontent-njs-c61  >
                                    <th _ngcontent-njs-c61 width="20%" class="align-L">Event Date/Time</th>
                                    <th _ngcontent-njs-c61 class="align-L">Event Name</th>
                                    <th _ngcontent-njs-c61 id="Result_Title_1" width="20%">Home</th>
                                    <th _ngcontent-njs-c61 id="Result_Title_2" width="20%">Away</th>
                                </tr>
                                </tbody>
                                {/* <tbody id="Result_Content"><tr id="tempTr">
                                    <td id="eventDate" class="align-L">2021-12-19 23:30</td>
                                    <td id="eventName" class="align-L">Mumbai Indians SRL T20 v Delhi Capitals SRL T20</td>
                                    <td><strong><span id="resultItem1">162/9</span></strong></td>
                                    <td><strong><span id="resultItem2">175/6</span></strong></td>
                                </tr></tbody> */}
                            </table>
                        }




                    </div>
                </div>







            </div>




        </div>
    )
}

export default Result
