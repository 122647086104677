import React from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


export const queryClient = new QueryClient();


const ReactQueryProvider = ({children}) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      {/*<ReactQueryDevtools*/}
      {/*    initialIsOpen={false}*/}
      {/*    // initialIsOpen={isDevMode}*/}
      {/*/>*/}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
