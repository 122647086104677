import React, { useState } from 'react'
import Rulesnregulation from './Rulesnregulation';


export default function AgeRestrictionDisclaimer(props) {
	const [rulesnreg, setrulesnreg] = useState(false)
	const [checked, setchecked] = useState(false);

	const handleclick = () => {
		setrulesnreg(true);
	}

	const handleChecked = () => {
		setchecked(!checked)
	}
	return (
		<React.Fragment>
			{rulesnreg == false &&
				<React.Fragment>
					<header _ngcontent-njs-c67="" class="announce-header">
						<h1 _ngcontent-njs-c67="" >AGE RESTRICTION DISCLAIMER</h1>
					</header>
					<div _ngcontent-njs-c67="" class="tc-wrap">
						<p>To play at this site, your age should be 18 years or above.</p>

						<p>You are required to truthfully state your age while registering. Minors and underage are strictly prohibited to participate in any activity on this site.</p>

						<p>Your account with this site may stand suspended till satisfactory documentation to prove your age is provided to us. If we find later that you are underage or minor we reserve the right to suspend or deactivate your account whichever we find appropriate.</p>

					</div>
					<footer>
						<ul class="age_box" _ngcontent-njs-c67="" >
							<li><label _ngcontent-njs-c67="" id="termVersion"
								onClick={handleChecked} className={`confirm-checkbox ${checked ? "checked" : ""}`}>Do not show again.</label></li>
							<li><a _ngcontent-njs-c67="" id="decline" href='/home' class="btn secondary" style={{color:"#fff"}}>I am below 18</a></li>
							<li><a _ngcontent-njs-c67="" id="accept" onClick={() => { handleclick() }} class="btn-send">I am 18 or above</a></li>
						</ul>
					</footer>
				</React.Fragment>
			}
			{rulesnreg &&

				<Rulesnregulation firstlogin={props.firstlogin} password={props.password} Newpassword={props.Newpassword} />

			}
		</React.Fragment>
	)
}
