import React from 'react'
import Cup from '../images/sprite/cup.gif'
import Transparent from '../images/sprite/transparent.gif'
import { Link } from "react-router-dom";
import { Homeannounce } from './Homeannounce'
import Promot from '../images/sprite/promot.gif'
export default function Footer(props) {





	return (
		<div _ngcontent-njs-c65="" >
			{/* <nav _ngcontent-njs-c65="" >
				<ul _ngcontent-njs-c65="" >
					<li _ngcontent-njs-c65=""  id="sports">
						<a _ngcontent-njs-c65=""  href='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img _ngcontent-njs-c65=""  className="icon-sports" src={Cup} alt="gif" />Sports
						</a></li>
					<li _ngcontent-njs-c65=""  id="inPlay">
						<a _ngcontent-njs-c65=""  href='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img _ngcontent-njs-c65=""  className="icon-inplay" src={Cup} alt="gif" />In-Play
						</a></li>
					<li _ngcontent-njs-c65=""  id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<a _ngcontent-njs-c65=""  href='/' className="ui-link">
							<img _ngcontent-njs-c65=""  className="icon-home" src={Cup} alt="gif" />Home</a></li>
					<li _ngcontent-njs-c65=""  id="multiMarket">
						<a _ngcontent-njs-c65="" href='/d/dash/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-pin" src={Cup} alt="gif" />Multi Markets
						</a></li>
					<li _ngcontent-njs-c65=""  id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>< img className="icon-account" src={Cup} alt="gif" />Account</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">< img className="icon-account" src={Cup} alt="gif" />Account</a>}
					</li>
				</ul>
			</nav> */}
			<nav>
				{/* <Homeannounce/> */}
				<ul class="mini-lobby">

					<li id="mini" className="main-nav">
						{props.isLoggedIn && <a onClick={() => { props.navcasinoclick(props.navbarcas) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true) }} style={{ paddingTop: '4px' }} className="ui-link">
							<img className="icon-promote" src={Promot} alt="mini" />
						</a>}
					</li>



					<li id="sports">
						<Link _ngcontent-njs-c65="" to='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img className="icon-sports" src={Transparent} />Sports
						</Link></li>


					<li id="inPlay">
						<Link _ngcontent-njs-c65="" to='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img class="icon-inplay" src={Transparent} />In-Play
						</Link></li>



					<li _ngcontent-njs-c65="" id="home">
						<Link _ngcontent-njs-c65="" to='/' className={`ui-link ${(props.tabMenu === 3) ? "select" : "null"}`}>
							<img class="icon-home" src={Transparent} />Home
						</Link></li>



					<li id="multiMarket">
						<Link _ngcontent-njs-c65="" to='/d/dash/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img class="icon-pin" src={Transparent} />Multi Markets
						</Link></li>
					<li id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>
							<img class="icon-account" src={Transparent} />Account
						</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">	<img class="icon-account" src={Transparent} />Account</a>}
					</li>
				</ul>
			</nav>
		</div>
	)
}
