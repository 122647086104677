import React from 'react';
import {marketKeys} from "../../utils";

const TabButtons = ({handleRefreshhh, sethighlightTab, highlightTab, marketData, ManualData, CompMatchData, TieMatchData, SuperMatchData, goalsSoccerApiMarketData, onCloseGoalMarketLayBack}) => {
    const url = window.location.href;
    const para = url.split('/');
    const eventT = para[6];

    return (
        <div id="naviMarket" className="mob-market-type ps ps--theme_default"
             data-ps-id="dd7c990b-079c-38b8-0677-cc1ec473ee97">
            {eventT == 138 && <ul id="naviMarketList">
                <li id="naviMarket_1_192573657" onClick={() => {
                    handleRefreshhh(500);
                    sethighlightTab(marketKeys.matchOdds)
                }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`}
                    style={{display: 'list-item'}}>
                    <a>{marketData.marketName}</a></li>
            </ul>}
            {eventT == 4 &&
                <ul id="naviMarketList">

                    <li id="naviMarket_1_192573657" onClick={() => {
                        handleRefreshhh(500);
                        sethighlightTab(marketKeys.matchOdds)
                    }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`}
                        style={{display: 'list-item'}}>
                        <a>Match Odds</a></li>

                    {ManualData.matchStatus === '1' &&
                        <li id="naviMarket_1_192573659" onClick={() => {
                            handleRefreshhh(500);
                            sethighlightTab(marketKeys.winToss)
                        }} className={`${(highlightTab === marketKeys.winToss) ? "select" : "null"}`}
                            style={{display: 'list-item'}}>
                            <a>To Win the Toss</a></li>}

                    {CompMatchData.matchStatus === '1' &&
                        <li id="naviMarket_1_192573659" href="" onClick={() => {
                            handleRefreshhh(500);
                            sethighlightTab(marketKeys.completedMatch)
                        }} className={`${(highlightTab === marketKeys.completedMatch) ? "select" : "null"}`}
                            style={{display: 'list-item'}}>
                            <a>Completed Match</a></li>}

                    {TieMatchData.matchStatus === '1' &&
                        <li id="naviMarket_1_192573659" onClick={() => {
                            handleRefreshhh(500);
                            sethighlightTab(marketKeys.tiedMatch)
                        }} className={`${(highlightTab === marketKeys.tiedMatch) ? "select" : "null"}`}
                            style={{display: 'list-item'}}>
                            <a>Tied Match</a></li>}

                    {SuperMatchData.matchStatus === '1' &&
                        <li id="naviMarket_1_192573659" onClick={() => {
                            handleRefreshhh(500);
                            sethighlightTab(marketKeys.superOver)
                        }} className={`${(highlightTab === marketKeys.superOver) ? "select" : "null"}`}
                            style={{display: 'list-item'}}>
                            <a>Super Over</a></li>}

                </ul>}

            {eventT == 1 &&
                <ul id="naviMarketList">

                    <li id="naviMarket_1_192656073" onClick={() => {
                        handleRefreshhh(500);
                        sethighlightTab(marketKeys.matchOdds)
                    }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`}
                        style={{display: 'list-item'}}>
                        <a>Match Odds</a></li>
                    {/* <li  id="naviMarket_1_192656073" style={{ display: 'list-item' }} className={`${(SoccerMenutab == 1) ? "select" : "null"}`} onClick={() => { setSoccerMenutab(1) }}  ><a >Match Odds</a></li> */}
                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('0.5')].matchStatus == 1 && (
                        <li id="naviMarket_1_192656077" style={{display: 'list-item'}}
                            className={`${(highlightTab == marketKeys.overUnderGoals("0.5")) ? "select" : "null"}`}
                            onClick={() => {
                                handleRefreshhh(500);
                                sethighlightTab(marketKeys.overUnderGoals("0.5"));
                                onCloseGoalMarketLayBack()
                            }}><a>Over/Under 0.5 Goals</a></li>
                    )}
                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('1.5')].matchStatus == 1 && (
                        <li id="naviMarket_1_192656121" style={{display: 'list-item'}}
                            className={`${(highlightTab == marketKeys.overUnderGoals("1.5")) ? "select" : "null"}`}
                            onClick={() => {
                                handleRefreshhh(500);
                                sethighlightTab(marketKeys.overUnderGoals("1.5"));
                                onCloseGoalMarketLayBack()
                            }}><a>Over/Under 1.5 Goals</a></li>
                    )}
                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('2.5')].matchStatus == 1 && (
                        <li id="naviMarket_1_192656118" style={{display: 'list-item'}}
                            className={`${(highlightTab == marketKeys.overUnderGoals("2.5")) ? "select" : "null"}`}
                            onClick={() => {
                                handleRefreshhh(500);
                                sethighlightTab(marketKeys.overUnderGoals("2.5"));
                                onCloseGoalMarketLayBack()
                            }}><a>Over/Under 2.5 Goals</a></li>
                    )}
                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('3.5')].matchStatus == 1 && (
                        <li id="naviMarket_1_192656122" style={{display: 'list-item'}}
                            className={`${(highlightTab == marketKeys.overUnderGoals("3.5")) ? "select" : "null"}`}
                            onClick={() => {
                                handleRefreshhh(500);
                                sethighlightTab(marketKeys.overUnderGoals("3.5"));
                                onCloseGoalMarketLayBack()
                            }}><a>Over/Under 3.5 Goals</a></li>
                    )}
                    {goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')]?.eventName && goalsSoccerApiMarketData[marketKeys.overUnderGoals('4.5')].matchStatus == 1 && (
                        <li id="naviMarket_1_192656119" style={{display: 'list-item'}}
                            className={`${(highlightTab == marketKeys.overUnderGoals("4.5")) ? "select" : "null"}`}
                            onClick={() => {
                                handleRefreshhh(500);
                                sethighlightTab(marketKeys.overUnderGoals("4.5"));
                                onCloseGoalMarketLayBack()
                            }}><a>Over/Under 4.5 Goals</a></li>
                    )}

                </ul>}

            {eventT == 2 &&
                <ul id="naviMarketList">
                    <li id="naviMarket_1_192573657" style={{display: 'list-item'}} className="select">
                        <a>Match Odds</a></li>
                </ul>}
            {eventT == 7522 &&
                <ul id="naviMarketList">
                    <li id="naviMarket_1_192573657" onClick={() => {
                        handleRefreshhh(500);
                        sethighlightTab(marketKeys.matchOdds)
                    }} className={`${(highlightTab === marketKeys.matchOdds) ? "select" : "null"}`}
                        style={{display: 'list-item'}}>
                        <a>Moneyline</a></li>
                </ul>
            }


            <div className="ps__scrollbar-x-rail" style={{left: '0px', bottom: '0px'}}>
                <div className="ps__scrollbar-x" tabIndex={0} style={{left: '0px', width: '0px'}}/>
            </div>
            <div className="ps__scrollbar-y-rail" style={{top: '0px', right: '0px'}}>
                <div className="ps__scrollbar-y" tabIndex={0} style={{top: '0px', height: '0px'}}/>
            </div>
        </div>
    )
}

export default TabButtons;
