import React,{ memo } from 'react';

const RunnerHeader = ({totalStake, maxStake, coinFactor, handleMaxStake, onHideMaxStack}) => {

    return (
        <>
            {maxStake &&
                <div  id="minMaxBox" class="limit_info-popup" style={{ display: ' flex' }}>
                    <a  class="close" onClick={onHideMaxStack} >Close</a>
                    <dl>
                        <dt  id="maxDt" >Max</dt>
                        <dd  id="minMaxInfo">{parseInt(maxStake) * coinFactor}</dd>
                    </dl>
                </div>}

            <a id="minMaxButton" class="bet-limit" onClick={handleMaxStake}></a>

                <dl  id="betsHead" className="bets-selections-head">
                    <dt  >

                        <a  className="a-depth" id="marketDepthBtn">Markets Depth</a>
                        <p  ><span>Matched</span>
                            <strong id="totalMatched">USD  {totalStake}</strong>
                        </p>
                    </dt>
                    <dd  className="mode-land" />
                    <dd  className="mode-land" />
                    <dd  >Back </dd>
                    <dd  >Lay </dd>
                    <dd  className="mode-land" />
                    <dd  className="mode-land" />
            </dl>
        </>
    );
};

export default memo(RunnerHeader);