import React, {useState} from 'react';

const CompetionTab = ({children}) => {
    const [isByCompetion, setIsByCompetion] = useState(false);

    const selectStyle = {"color":"#0074c4","background":"#fff","boxShadow":"0 0 3px 0 rgba(0, 0, 0, .15)"}
    return (
        <>
            <div style={{
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "height": "10.6666666667vw",
                background: "#EEEEEE"
            }}>
                <ul style={{
                    "display": "flex",
                    "background": "#e3e3e3",
                    "boxShadow": "inset 0 1px 3px 0 rgba(0, 0, 0, .15)",
                    "borderRadius": "1.6vw",
                    "width": "80%",
                    "padding": "0.2666666667vw",
                    "height": "fit-content"
                }}>
                    <li id="sortByTime" style={{
                        "flex": "1",
                        "height": "8vw",
                        "borderRadius": "1.3333333333vw",
                        "fontSize": "3.2vw",
                        "fontWeight": "bold",
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        ...(!isByCompetion ? selectStyle : {})
                    }}
                    onClick={() => setIsByCompetion(false)}
                    >by Time
                    </li>
                    <li id="sortByCompetition" style={{
                        "flex": "1",
                        "height": "8vw",
                        "borderRadius": "1.3333333333vw",
                        "fontSize": "3.2vw",
                        "fontWeight": "bold",
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        ...(isByCompetion ? selectStyle : {})
                    }}
                    onClick={() => setIsByCompetion(true)}
                    >by Competition
                    </li>
                </ul>
            </div>
            {children(isByCompetion)}
        </>
    )
}

export default CompetionTab;