import {useQuery} from '@tanstack/react-query';
import {queryClient} from './provider';

// const reactQueryHelper = (key, initialData) => [
//   useQuery([key], () => initialData, {enabled: false, initialData}).data,
//   (value) => queryClient.setQueriesData([key], value),
// ];

// const reactQueryHelper = (key, initialData) => {
//
//   let getData = queryClient.getQueryData({ queryKey: [key] });
//   if(!getData && initialData){
//     queryClient.setQueryData([key], initialData);
//   }
//   let stateData = queryClient.getQueryData({ queryKey: [key] });
//
//   return [
//     stateData ? stateData : initialData,
//     (value)=> queryClient.setQueryData([key], value),
//   ]
// }



export const setRqData = (key, value) =>  queryClient.setQueryData([key], value);
export const getRqData = (key) =>  queryClient.getQueryData({ queryKey: [key] });
export const useRqRealTimeData = (key) => useQuery([key]);
export const invalidRqData = (key) =>  queryClient.invalidateQueries(key);

export const useRqDataWithInitialData = (key, initialData, config = {}) => useQuery([key], () => initialData, {
  enabled: false,
  ...config,
  initialData,
});



export const useRqGlobalInitialDataState = (key, initialData) => {
  const data = useRqDataWithInitialData(key, initialData).data;
  return {
    data,
    setRqData
    // setRqData: val => setRqData(key, val)
  }
}

export const useRqGlobalState = (key) => {
  const data = useRqRealTimeData(key).data;
  return {
    data,
    setRqData
    // setRqData: val => setRqData(key, val)
  }
}





