import React, { useEffect, useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'


const io = require('socket.io-client');
const ranNum = Math.floor(Math.random() * 4) + 1;
var ENDPOINT;
if (ranNum == 1) {
  ENDPOINT = "https://liveapi247.live:2053";
}
else if (ranNum == 2) {
  ENDPOINT = "https://liveapi247.live:2083";
}
else if (ranNum == 3) {
  ENDPOINT = "https://liveapi247.live:2087";
}
else {
  ENDPOINT = "https://liveapi247.live:2096";
}

var socket;
var c1 = 0, c2 = 0, c3 = 0, c4 = 0, c5 = 0, c6 = 0, c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0, c13 = 0, c14 = 0, c15 = 0, c16 = 0, c17 = 0, c18 = 0;
var s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22, s23, s24, s25, s26, s27, s28, s29, s30, s31, s32, s33, s34, s35, s36;
let eventid = "28127348";

export default function WinnerMarket(props) {

  const [winnerData, setwinnerData] = useState([]);
  const [totalStake, settotalStake] = useState(0);

  useEffect(() => {
    socket = io(ENDPOINT);
    console.log('socket connected');
    socket.on('connect', function (data) {
      socket.emit('room1', eventid);
      console.log('data', data);
    });
    return () => {
      socket.close();
      console.log('socket disconnected');
      // props.setOnClick(0);
      s1 = -1; s2 = -1; s3 = -1; s4 = -1; s5 = -1; s6 = -1; s7 = -1; s8 = -1; s9 = -1; s10 = -1; s11 = -1; s12 = -1; s13 = -1; s14 = -1; s15 = -1; s16 = -1; s17 = -1; s18 = -1; s19 = -1; s20 = -1; s21 = -1; s22 = -1; s23 = -1; s24 = -1;
      s25 = -1; s26 = -1; s27 = -1; s28 = -1; s29 = -1; s30 = -1; s31 = -1; s32 = -1; s33 = -1; s34 = -1; s35 = -1; s36 = -1;
    }
  }, [])

  useEffect(() => {

    socket.on(eventid, (val) => {

      var value = JSON.parse(val);
      if (value[0].messageType == 'Winner_market_rate') {
        let temp = value;
        settotalStake(temp[0].totalMatched);
        setwinnerData(temp);
      }

    })

    return () => {
      socket.removeAllListeners(eventid);


    }


  }, [eventid])


  return (
    <div>
      <div _ngcontent-njs-c61="" id="liveMatchGameHead" class="game-head">


        <div _ngcontent-njs-c61="" id="matchTrackerWrap" class="match-tracker-wrap">


          <ul _ngcontent-njs-c61="" id="liveMatchTrackerBtn" class="fm-match-button">
            <li _ngcontent-njs-c61="" ><a _ngcontent-njs-c61="" id="liveMultiMarketPin" style={{ cursor: 'pointer' }} name="gameHeadPin" class="favourite-pin" title="Add to Multi Markets" eventtype="4" eventid="30035305" marketid="1.173506114"></a></li>
            <li _ngcontent-njs-c61="" >
              <a _ngcontent-njs-c61="" style={{ cursor: 'pointer' }} class="fm-refresh-button">

              </a></li>
          </ul>
        </div>

        {/*<!-- Game Scroes Event -->*/}
        <div _ngcontent-njs-c61="" id="scoresEvent" class="fm-match-odds">Winner<img src={Transparent} /></div>




        {/*<!-- Game Information -->*/}
        <ul _ngcontent-njs-c61="" class="game-info" style={{ display: 'block' }}>

          <li _ngcontent-njs-c61="" id="gameInfoDate" class="green"><img _ngcontent-njs-c61="" class="icon-irun" src={Transparent} /> In-Play</li>

        </ul>

        <dl className="game-matched" style={{ display: 'block' }}><dt>Matched</dt><dd id="liveGameMatched">USD {totalStake}</dd></dl>



      </div>

      <div _ngcontent-njs-c61 id="fullMarketBetsWrap" className="bets-wrap" eventtype={4} eventid={30035305} marketid="1.173506114">
        <span _ngcontent-njs-c61 id="selectionCounts" className="refer_only">10 Selections</span>
        <table _ngcontent-njs-c61 id="fullMarketBoard" className="fm-bets" style={{}}>
          <tbody _ngcontent-njs-c61>
            <tr _ngcontent-njs-c61 className="fm-bet-zone">
              <td _ngcontent-njs-c61 />
              <td _ngcontent-njs-c61 id="backPercent" className="refer-bet" colSpan={2}>101.7%</td>
              <td _ngcontent-njs-c61>
                <a _ngcontent-njs-c61 id="backAll" className="back-all"><img _ngcontent-njs-c61 src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /><span> Back all</span></a>
              </td>
              <td _ngcontent-njs-c61>
                <a _ngcontent-njs-c61 id="layAll" className="lay-all"><img _ngcontent-njs-c61 src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /><span>Lay all</span></a>
              </td>
              <td _ngcontent-njs-c61 id="layPercent" className="refer-book" colSpan={2}>97.9%</td>
            </tr>

            {winnerData.map(function (item, index) {
              return (
                <tr _ngcontent-njs-c61 id="fullSelection_4164048" eventtype={4} eventid={30035305} marketid="1_173506114" selectionid={4164048} eventname="Royal Challengers Bangalore v Rajasthan Royals" status={1} sortpriority={1} className="null">
                  <th _ngcontent-njs-c61 className="predict">
                    <p _ngcontent-njs-c61>
                      <a _ngcontent-njs-c61><img _ngcontent-njs-c61 className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>{item.runnerName}
                    </p>
                  </th>
                  <td _ngcontent-njs-c61 id="back_3" className="back-3 spark" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.88" inplay={0}>
                    <a>{item.runner1BackRate1}<span>{item.runner1BackSize1}</span></a>
                  </td>
                  <td _ngcontent-njs-c61 id="back_2" className="back-2 spark" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.89" inplay={0}>
                    <a>{item.runner1BackRate2}<span>{item.runner1BackSize2}</span></a>
                  </td>
                  <td _ngcontent-njs-c61 id="back_1" className="back-1 null spark" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.9" inplay={0}>
                    <a>{item.runner1BackRate3}<span>{item.runner1BackSize3}</span></a>
                  </td>
                  <td _ngcontent-njs-c61 id="lay_1" className="lay-1 null spark" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.97" inplay={0}>
                    <a>{item.runner1LayRate1}<span>{item.runner1LaySize1}</span></a>
                  </td>
                  <td _ngcontent-njs-c61 id="lay_2" className="lay-2 spark" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.98" inplay={0}>
                    <a>{item.runner1LayRate2}<span>{item.runner1LaySize2}</span></a>
                  </td>
                  <td _ngcontent-njs-c61 id="lay_3" className="lay-3 null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}>
                    <a>{item.runner1LayRate3}<span>{item.runner1LaySize3}</span></a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>


  )
}
