import React from 'react'
import Transparent from '../images/sprite/transparent.gif'
import Loading from '../images/sprite/loading40.gif'

export default function Multimarketdesk() {
  return (
    <div>

      <div>
        <div _ngcontent-njs-c64 >
          <h2 _ngcontent-njs-c64 id="header">Multi Markets</h2>
          <p id="noMultiMarkets">There are currently no followed multi markets.</p>

        </div>
      </div>
    </div>
  )
}
