
export const RQ_KEY = {
    // STORE KEYS
    STORE_USER_DATA: 'STORE_USER_DATA',
    STORE_ALL_EVENTS_DATA: 'STORE_ALL_EVENTS_DATA',
    STORE_SPORTS_DATA: 'STORE_SPORTS_DATA',
    STORE_SPORTS_COUNT_DATA: 'STORE_SPORTS_COUNT_DATA',
    STORE_SPORTS_IN_PLAY_DATA: 'STORE_SPORTS_IN_PLAY_DATA',
    STORE_SPORTS_TODAY_DATA: 'STORE_SPORTS_TODAY_DATA',
    STORE_SPORTS_TOMORROW_DATA: 'STORE_SPORTS_TOMORROW_DATA',
    STORE_CHECK_FANCY_DATA: 'STORE_CHECK_FANCY_DATA',
    STORE_CHECK_TV_DATA: 'STORE_CHECK_TV_DATA',
    STORE_CHECK_BOOKMAKER_DATA: 'STORE_CHECK_BOOKMAKER_DATA',
    STORE_CHECK_SCORE_DATA: 'STORE_CHECK_SCORE_DATA',
    STORE_BANNER_DATA: 'STORE_BANNER_DATA',
    STORE_NEWS_DATA: 'STORE_NEWS_DATA',
};

