import React, { useState, useEffect } from 'react'
import './Navcasino.css'
import loading_BG from '../../images/Banner/loading_BG.gif'
import updown from '../../images/casino/7up7down.webp';
import miniandar from '../../images/casino/andarbahar-enter.webp'
import { getCasinoObj } from '../../casino-utils';
import {useHistory} from "react-router-dom";
import spaceVideo from '../../images/Banner/space_video.c7f6a7d7.mp4';
import queryString from "query-string";
import LiveLoading from "../../images/sprite/cover-live.png";
import { Player, Shortcut, VolumeMenuButton, ControlBar, FullscreenToggle } from "video-react";


const Navcasino = (props) => {
    const history = useHistory();
    const [loading, setloading] = useState(false);


    useEffect(() => {
        setTimeout(() => { setloading(true); }, 3000);
    }, [loading])

    return (
        <React.Fragment>
            <div data-v-55d85284="" id="luckco7-frame" className="luckco7-frame luckco7-frame-active" style={{ zIndex: 9998 }}>
                <Player autoPlay muted playsInline loop className="spaceBgVideo">
                    <source src={spaceVideo} />
                    <Shortcut clickable={false} />
                    <ControlBar muted disableDefaultControls autoHide={true} />
                </Player>
                {/*<video className="spaceBgVideo" controls={false} autoPlay loop muted>*/}
                {/*    <source src={spaceVideo} type="video/mp4" />*/}
                {/*</video>*/}
                {/**/}
                <div data-v-55d85284="" >
                    {loading === false && <div data-v-55d85284="" id="luckco7-frame-loading" autoplay="autoplay" loop="loop" muted="muted" playsinline="" style={{ width: '100%', height: '100%', borderRadius: '16px 14px 0px 0px', display: 'block' }}>
                        <img data-v-55d85284="" style={{height: "72vw"}} id="loading-source" src={loading_BG} type="video/mp4" /></div>}
                    {loading === true &&
                        <> <img data-v-55d85284="" src="data:image/webp;base64,UklGRsAHAABXRUJQVlA4WAoAAAAYAAAAHQEAOwAAQUxQSA8BAAABgFtt2/Lk+UZImzkCVJkJd3cpfQCGcdnCnV/i39u930/FXUbEBKji+uHDa2cQo/3ze6GSkYt2NAbPVeF2Fo3CMqQdtA3EPaU2P4zEMqScRUNxR4nX0VAsgq/2ajDuyN3oGIxFcNU6RuO23K9GYyH3teG47TqLOBTyNqPhuOX6MBwLefeMxw1Xm4dC3l3jccOV8ZDLOxJ5WHddGI653C0eVl31iEMu96HhuOq7xyGT/xGHlYSChkyJPRpWUgYwZEqNMCwnGYuZ2FhkIxMbi2z8io0FNn7Fxhwbv2Jjjo1fsTHLxo/YmGXjR2xMs/EjNqbY+BYbU2x8i40JNr7FxjgbX2JjnI0vsTHKxpfYGP0rAFZQOCA+BgAA0CAAnQEqHgE8AD6RQppJpaOiIis1KbCwEgliAMm4iqzcDXjbnr9Fe2x8wHnVelj0AP236z/0M/Lb9mL+7/8X9gLDx+Jdkx/jfYB2QymwjnI+VkDUPI99bcAAaYF0rpA7mxRIq1q9evXr169cXxHGXxMQ15N08c6DRzEQypp7+kdG+I/otZUTHf5itfYtem53M8B39H+QVjQPgYGjJ4x08aQ4oBGjUUpGwgUcU1/lq5OldmofauuHk+sBpHGsl7gwmJiSgZGOlqtbnY4pSPa4a7fdwuRHn1to7M2eQfU9THfZ5CgNrwtCOTJ48+NSagl5XmRk8evhy4Hzfs0bPwPX5gWKf+lv0TY4YKj6aRCz0AD+/VP3++VpvJt/9bmGxe+0x+JshznikZ56WNNgSGAk//ui+ZDpm1ih+tqC+rBi/F0X88EyQivOwr9Hxh05VNY7oIkZPomBmsabXJZEFWAJFNj97QmmqIz7+j/FReQ7YaTUTlwfonfp8BqCddBX9sWvkOBo7c6FdWH210leZ29OhNylY
                FL4is/cDePl+5tBSKBIbMiSRSvyLPDd/lXuJGzxn4px1WPNfkL84UxWWuY0ICPh
                Y6SZX+woajrhQ+mlUWaG5v8QtE0MXs9dwWzvy9F9h8PIDaX38F783qvDDKf1vhkAwUdgkpU
                7fg6nKoD4XPHcH2ZPQaOzTBtah17A+lnrbtg33D1Kiy/zbalvI4jMC6pW1tU+XBsl2Cni8J7aN5/cWqh
                AnSOHkHSXUsbRpXt6CUMQ09y3P7M/vCP0slZsYDtgFf3zr8a8iThdf0pb4k4M5sgUhu/g+YSw8H5hC5f2k3u
                mIxEvN/27ny0lRe6k8oYmd1FbJxmPXjkfyJgfKE/0qJBiwbypgyOwu/u7Hl++2QhdJf98f2RsS0gzCTx66gYA/bM
                f4kgOKM6Hbf2Kvd3fK96p4Aqhj4kcvzYPJCScV4weQeKz6I6ZIvS3LRwuwK1sxAubmkOAO7k7BA8cXpGhULF7qGK/
                HfKLrzt9im0rM9P0/5l8T8eqH/v00jRKGsSQS895yN+phV7MfnmsoBKB2UYL8zEFY+NswF/iF5xR00MbesMxZKC
                SJ5j9VXVGELfGwsvyHd7fcf33E0HtP72XvR2apDNFKNw8SpkJKnnS2zV8N7i2HDjlR4KeC0peK3OfIG6MV7F9yE
                FveTXl9SoyI3XGC+PhQ+Ud5iTJ2jx85MZzRWwsbED0ELrhXAL9Om926a5XMk9YWek/WNX341abLPHZ7WfzeVWOPChrfM+yMGJK+HL+W38RySMjq6uZoVwnH0FK33g4DXr/ogQvqXwE4H3vFnUH/6hWNkbRlH
                JP3/O+sTpV78hEyJL1UDvMH+kgcIEVbpqVFwpZpv/UiCw1Lz4hbwqfI1lXfnlhjFdJdeFJ
                NkDAfUpntXC8Z6RHIjD9za2LHxZ0HRGt/lDlQvfO7Fr2lgpTq0vdSfxhi7rMWuU4XhfnCm
                Z3Dz4kBjLvv2hBf+ewoMifKQZTIAE1g8aMBwmIRD9N/vl/5BejjThYkSGrMHQrHQdAXTtnyjn
                ui+C6CJrMQ/jKaz5aR9YKkrleszqgcmk4A+bxHHY0WYHKsJvHLo00WRGZ9wTp8UYwowvzhTG
                yv2gGv9sxe9+b/PX3DpDKdUcn2QBSkR49IF29sAQ/b3WI5pwbLr91UawAeDFQUyAaorq+38g
                lIxGAdwzEZo6cFvN5pEi+p8Z8Wvrm0u4En/AsehNV37CcqqiPO+CBUYcnUQqVgBdWHCoE47k
                aTM/bC0iNH5nGVgnmM7pjeDoSrKxs1cUl3q1MF5SjDllrTjMCXYjtHrz4rIJ0qWc2x20OYAGs
                6bn9fCfeQw3BfBRWdqUhU40tc3tiJf6To9m2LDvPkSFMhOjoAAMm4Kqe3JHIwqYDGZlBK2sei
                kzG9D6OG4H2Tgf0W06RI8bIVbxUhYFrFWdH81Gagqs8xm6/cqEsGJnMPuyOIMalE1/IMYAADu
                Kgb4FctKTT3ffjEYy8MiwaO8tmp4m5jvmwkAwTXvNvl0lwaF1zdRSGcAZGCRNM8u94jkjovwJ
                ej3pn+yAn38TQTR3+NRIarHadT1d+XIyvfA3aBV6hzcmgtbOqUk+P75bNj0KMawAAAABFWElGR
                AAAAE1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAR6
                gAwAEAAAAAQAAADwAAAAA"
                                style={{ width: '40vw' }} />
                            <span data-v-55d85284="" data-v-302cf84b className="header__number">2</span>
                            <img data-v-55d85284="" onClick={() => { props.navcasinoclick(props.navbarcas) }} src="data:image/webp;base64,UklGRsQDAABXRUJQVlA4WAoAAAAYAAAAYwAAOwAAQUxQSPgAAAABgBvbturmSjJjH5S5EWZmCh07dRkYuQemDtSBGUOx3s3e/3/m7DwiGLltG4lpq9nO84iya0wIzdpf8WTAUs6xS02oFUfTrX9Xm8pFLoUD15vGZYr154JTWky0r8jDDSHur52AuJeGCfHGQtyuKG+OxNev6qr0RO2o8k9E/BmRcIhIrSVmS5bfRvMjq9mPyBUijahN3Rwh8h61rvuxIbKOyFM834g/8pqqkhHSNEQsKQgrCPaFsIRgn5oQsRRBWJSEiCFiqYIwj2AfehCxlEGYk5M2CDMI9o4wg2BvCFNBCiKGiGUJwmQQgohlC4G9IowHAntBGBOJA1ZQOCBaAgAAcA0AnQEqZAA8AD6RQppJpaOiJCpXGbCwEglkAMfcq49H0ESxwor0RbxFvPjTYRQEqB7hngA1FiCek6qNAhZnbai5bKDM3Nlyv9Szo/SyjJue0J5s7No8X02F73e3YBL9BmuouFE5lCHdI8Ops1lAlFo2N3AA/votcag+/KLwB/zqiZKtpFDHm4dkmFjHb61vc8ZRoktuabfaLlFKJQisOQg+diK+GIZDhukBefZlNSYkENe9vIqRT1O4C1tEnuE1KLBo1m84sz//9pRMXPxmP//dpwCIoIBOJSYxWNKNbD75sLbA5Du24qkD4ZAZxxvpUTR7pu6WSqpvO7EaoZu3iBt5+Q0GrckRaLaZyD8sYtVJf61dJ394tfZy4pzs30vpTMykuFeKv26vzHNd+5mMtloBJK/0/g7NygBOnWblU1qkbiRcde7eIkeGLFGzu/TfVFrb8FDamtCWtZxXhxTz1y4j7KnHvJVYVd25VxvmDHrB0ZtcJ4RHn0lGCNcT4uMW+JEpRezwEHtC8/ffpqQP5Ut0frTXnPKbx7FEfLKf9Sj7izTO7lphZaJKcSkxisQP3xDnPtFfTt3Oena6GFUenCPnyNheB3fSuE9t/XaVWBBNLZWKFgsEQN/8OE6K+LyXq7+c6cFWJwzTUEmQ/1hFFcSXj0ISsHwMAsF9+uvbKmTpq9dvWgL7/HavqgGHB6S+Mqpxc2wfyWzqinv+tYyP/3G0mVDDpXitUac2UF9h8GtNNlLespqr6ybh/+f6Gfp/mPtXmfrFoGWaneBC6E5hSR6iHlIB90wAAABFWElGRAAAAE1NACoAAAAIAAGHaQAEAAAAAQAAABoAAAAAAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAGSgAwAEAAAAAQAAADwAAAAA"
                                 style={{ height: '8vw', float: 'right' }} />
                            <div data-v-55d85284="" id="miniGameIframe" name="miniGameIframe&quot;" scrolling="no">
                                <div data-v-55d85284="" className="card-wrapper lobby">
                                    <div data-v-55d85284=""
                                         onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('7 Up 7 Down'))}`) : props.openLogin()}
                                         className="game-card gameCard">
                                        <div data-v-55d85284="" className="game-img">
                                            <img   data-v-55d85284="" src={updown} style={{ display: 'flex', justifyContent: 'center', height: '32vw', borderRadius: '11px' }} /></div>
                                        <div data-v-55d85284="" className="player">
                                            <div data-v-55d85284="" className="player__icon">
                                                <img data-v-55d85284="" src="data:image/webp;base64,UklGRmIBAABXRUJQVlA4WAoAAAAYAAAAGQAAGQAAQUxQSMkAAAABkHPbtrFnO7Ztu7RtO31GyvS2VTqpjT/A1rZt6zN27PQRMQH4ah26zlYq5YDA64UyyedF/iXJZRkIr5Bjos8RP+KjFUAZydTn2PPxSSCBZO9zfJ9YB+JJDj9H6e9jbUAWyernoPiRn5ZAH3mp8yyRgj/khifgRM7b4YUyrlaCAJS9zATw4Y2SOmf2t/prIqQfJMbIPhEzwad/NxoBhmfX1eoPGvj8a18gmdy1BUL40gMBWJNcArJeRC3okqQ4cl6mA50HEm/yZQIAVlA4ICYAAADQAgCdASoaABoAPpFEnUqlo6KhqAgAsBIJaQAAPaOgAP74zxrAAEVYSUZEAAAATU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAAA="
                                                     style={{ width: '4vw', height: '4vw', paddingLeft: '0.9vw', paddingTop: '0.9vw' }} /></div><span data-v-55d85284="" className="player__number">234</span></div>
                                        <div data-v-55d85284="" className="game-name">
                                            <p data-v-55d85284="" className="game-name__text">7up7down</p>
                                        </div>
                                    </div>
                                    <div data-v-55d85284="" className="game-card gameCard"
                                         onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Andar Bahar'))}`) : props.openLogin()}
                                    >
                                        <div data-v-55d85284="" className="game-img">
                                            <img data-v-55d85284="" src={miniandar} style={{ display: 'flex', justifyContent: 'center', height: '32vw', borderRadius: '11px' }} /></div>
                                        <div data-v-55d85284="" className="player">
                                            <div data-v-55d85284="" className="player__icon">
                                                <img data-v-55d85284="" src="data:image/webp;base64,UklGRmIBAABXRUJQVlA4WAoAAAAYAAAAGQAAGQAAQUxQSMkAAAABkHPbtrFnO7Ztu7RtO31GyvS2VTqpjT/A1rZt6zN27PQRMQH4ah26zlYq5YDA64UyyedF/iXJZRkIr5Bjos8RP+KjFUAZydTn2PPxSSCBZO9zfJ9YB+JJDj9H6e9jbUAWyernoPiRn5ZAH3mp8yyRgj/khifgRM7b4YUyrlaCAJS9zATw4Y2SOmf2t/prIqQfJMbIPhEzwad/NxoBhmfX1eoPGvj8a18gmdy1BUL40gMBWJNcArJeRC3okqQ4cl6mA50HEm/yZQIAVlA4ICYAAADQAgCdASoaABoAPpFEnUqlo6KhqAgAsBIJaQAAPaOgAP74zxrAAEVYSUZEAAAATU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAAA="
                                                     style={{ width: '4vw', height: '4vw', paddingLeft: '0.9vw', paddingTop: '0.9vw' }} /></div>
                                            <span data-v-55d85284="" className="player__number">80</span></div>
                                        <div data-v-55d85284="" className="game-name">
                                            <p data-v-55d85284="" className="game-name__text">Andar</p>
                                        </div>
                                    </div>
                                </div>
                            </div></>}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Navcasino
