import ms from "milliseconds";
import {inPlayDataFilter, todayDataFilter, tomorrowDataFilter} from "../../utils/sportsHelper";
import {RQ_KEY} from "./constant";
import { useQuery} from "@tanstack/react-query";
import {sportsKeyByName} from "../../utils/constant";
import {setRqData} from './rq-helper';
import {objIsNotEmpty} from '../../mobilecomponent/utils';
import {
    getSportsDataApiRq,
    getCheckFancyApiRq,
    getCheckTvApiRq,
    getCheckBookmakerApiRq,
    getCheckScoreApiRq, getBannerApiRq, getNewsApiRq, getSportsCountApiRq, getAllEventsApiRq
} from './api-actions';

const defaultConfig = {
    onError: (e)=> {
        console.error('api error', e)
    }
}


export const useSportsData = (config = {}) => {

    // let inplayData = {
    //     cricket: [],
    //     soccer: [],
    //     tennis: [],
    //     basketBall: []
    // }

    return useQuery(
        [RQ_KEY.STORE_SPORTS_DATA],
        async () => await getAllEventsApiRq(),
        {
            ...defaultConfig,
            ...config,
            onSuccess: (rsp)=> {

                if(config.onSuccess) {
                    config.onSuccess();
                }
                if(rsp && objIsNotEmpty(rsp)) {
                    const events = rsp;
// console.log('events',events)
                    // console.log('tomorrowDataFilter(events[sportsKeyByName.CRICKET])',tomorrowDataFilter(events[sportsKeyByName.CRICKET]))
                    // // inplay data
                    // setRqData(RQ_KEY.STORE_SPORTS_IN_PLAY_DATA, {
                    //     cricket: inPlayDataFilter(events[sportsKeyByName.CRICKET]),
                    //     soccer: inPlayDataFilter(events[sportsKeyByName.SOCCER]),
                    //     tennis: inPlayDataFilter(events[sportsKeyByName.TENNIS]),
                    //     basketBall: []
                    //     // basketBall: inPlayDataFilter(events[sportsKeyByName.BASKET_BALL])
                    // });
                    // // today data
                    // setRqData(RQ_KEY.STORE_SPORTS_TODAY_DATA, {
                    //     cricket: todayDataFilter(events[sportsKeyByName.CRICKET]),
                    //     soccer: todayDataFilter(events[sportsKeyByName.SOCCER]),
                    //     tennis: todayDataFilter(events[sportsKeyByName.TENNIS]),
                    //     basketBall: []
                    //     // basketBall: todayDataFilter(rsp[sportsKeyByName.BASKET_BALL])
                    // });
                    // // tomorrow data
                    // setRqData(RQ_KEY.STORE_SPORTS_TOMORROW_DATA, {
                    //     cricket: tomorrowDataFilter(events[sportsKeyByName.CRICKET]),
                    //     soccer: tomorrowDataFilter(events[sportsKeyByName.SOCCER]),
                    //     tennis: tomorrowDataFilter(events[sportsKeyByName.TENNIS]),
                    //     basketBall: []
                    //     // basketBall: tomorrowDataFilter(rsp[sportsKeyByName.BASKET_BALL])
                    // });
                    // console.log('rsp sp',rsp)
                }

            },
            // cache restore when run after 10 min this query
            staleTime: ms.minutes(10),
        }
    )
}




export const useSportsCountData = (config = {}) => {
    return useQuery(
        [RQ_KEY.STORE_SPORTS_COUNT_DATA],
        async () => await getSportsCountApiRq(),
        {
            ...defaultConfig,
            ...config,
            staleTime: ms.minutes(10),
        }
    )
}

export const useCheckTvData = (config = {}) => {
    return useQuery(
        [RQ_KEY.STORE_CHECK_TV_DATA],
        async () => await getCheckTvApiRq(),
        {
            ...defaultConfig,
            ...config,
            staleTime: ms.minutes(5)
        }
    )
}


export const useCheckBookmakerData = (config = {}) => {
    return useQuery(
        [RQ_KEY.STORE_CHECK_BOOKMAKER_DATA],
        async () => await getCheckBookmakerApiRq(),
        {
            ...defaultConfig,
            ...config,
            staleTime: ms.minutes(5)
        }
    )
}

export const useCheckFancyData = (config = {}) => {
    return useQuery(
        [RQ_KEY.STORE_CHECK_FANCY_DATA],
        async () => await getCheckFancyApiRq(),
        {
            ...defaultConfig,
            ...config,
            staleTime: ms.minutes(5)
        }
    )
}
export const useCheckScoreData = (config = {}) => {
    return useQuery(
        [RQ_KEY.STORE_CHECK_SCORE_DATA],
        async () => await getCheckScoreApiRq(),
        {
            ...defaultConfig,
            ...config,
            staleTime: ms.minutes(5)
        }
    )
}


export const useBannerData = (config = {}) => useQuery(
    [RQ_KEY.STORE_BANNER_DATA],
    async () => await getBannerApiRq(),
    {
        ...defaultConfig,
        ...config,
        staleTime: ms.minutes(30)
    }
)
export const useNewsData = (payload, config = {}) => useQuery(
    [RQ_KEY.STORE_NEWS_DATA],
    async () => await getNewsApiRq(payload),
    {
        ...defaultConfig,
        ...config,
        staleTime: ms.minutes(30)
    },
)