// import moment from "moment-timezone";
import moment from 'moment'
require('moment-timezone');

// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const timeZone = moment.tz.guess();

// console.log('timeZone',timeZone)
// console.log('timeZone', moment.tz.guess())

// console.log('timeZone mm ', moment.utc().tz())

export const sportsDataFilter = (data) => {
       const arr = Array.from(data || []);
    if(arr.length !== 0) {
        return arr.map((item) => {
            return Object.assign({
                ...item,
                marketStartTime: moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss'),
            })
        })
    } else {
        return [];
    }
}

export const homeGetSportsCount = (data) =>{
       const arr = Array.from(data || []);
    if(arr.length !== 0) {
        let count = 0;
        arr.map((item) => {
            if (moment() >= moment(item.marketStartTime)) {
                count = count + 1;
                // console.log('count',count + 1)
            }
        });
        return count;
    } else {
        return 0;
    }
}

export const inPlayDataFilter = (data) => {
       const arr = Array.from(data || []);
    if(arr.length !== 0){
        arr.map(item => {
            item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
            return item;
        })

        arr.sort((a, b)=> moment(a.marketStartTime) - moment(b.marketStartTime));
        let arrData = [];
        arr.map((item) => {
            if (moment() >= moment(item.marketStartTime)) {
                arrData.push(item);
            }
        });
        return arrData;
    } else {
        return []
    }
}


export const todayDataFilter = (data) => {
    const arr = Array.from(data || []);

    if(arr.length !== 0){

        arr.map(item => {
            item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
            return item;
        })
        arr.sort(function (a, b) {
            return moment(a.marketStartTime) - moment(b.marketStartTime);
        });

        let arrData = [];

        arr.map((item) => {
            let str = item.marketStartTime;
            let res = str.slice(11, 16);
            let today = moment();
            let thatDay = moment(item.marketStartTime);

            if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
                arrData.push(item);
            }
        });



        // arr.map(item => {
        //     item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
        //     return item;
        // })
        // arr.sort(function (a, b) {
        //
        //     return moment(a.marketStartTime) - moment(b.marketStartTime);
        // });
        // let obj = [];
        //
        // arr.map((item) => {
        //
        //     var str = item.marketStartTime;
        //     var res = str.slice(11, 16);
        //     var today = moment();
        //     var thatDay = moment(item.marketStartTime);
        //
        //     if (today.isSame(thatDay, 'day') && moment(item.marketStartTime).isAfter(moment(), 'seconds')) {
        //         obj.push(item);
        //     }
        //
        // });
        // return obj;

        return arrData;
    } else {
        return []
    }
}


export const tomorrowDataFilter = (data) => {
       const arr = Array.from(data || []);
    if(arr.length !== 0){
        arr.map(item => {
            item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
            return item;
        })
        arr.sort((a, b)=> moment(a.marketStartTime) - moment(b.marketStartTime));
        let arrData = [];

        arr.map((item) => {
            let tommorow = moment().add(1, 'day');
            if (moment(item.marketStartTime).isSame(tommorow, 'day')) {
                arrData.push(item);
            }
        });
        return arrData;
    } else {
        return []
    }
}

export const sportsTabFilter = (arr) =>{
    if(arr.length !== 0) {
        arr.map(item => {
            item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
            return item;
        })
        arr.sort(function (a, b) {
            return moment(a.marketStartTime) - moment(b.marketStartTime);
        });
        return arr;
    } else {
        return []
    }
}



export const sportsEventDataFilterCompetionName = async (data) => {
    const sportsData = [...data];
    let filterByGroupArr = sportsData?.reduce(function(result, value) {

        if(!value.CompetionName || value.CompetionName == null || value.CompetionName == 'null' || value.CompetionName === ''){
            value.CompetionName = 'Others';
        }

        result[value.CompetionName] = result[value.CompetionName] || [];
        // console.log('result',result, value)

        let CompetionResult = result[value.CompetionName];

        CompetionResult.push({ ...value });
        return result;
    }, {});
    const objKeys = Object.keys(filterByGroupArr || {});
    const marketData = await new Promise((resolve, reject) => resolve(objKeys));

    if(marketData.length !== 0) {
        return {
            filterByGroupArr,
            competionNames: marketData
        }
    } else {
        return {
            filterByGroupArr: [],
            competionNames: []
        }
    }
}

