import React, { useState, useEffect } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import Loading from '../images/sprite/loading40.gif'
import axios from 'axios';

export default function FancyBook(props) {

    const [refresh, setrefresh] = useState(true);
    const [bookList, setbookList] = useState([]);

    useEffect(() => {

        // if(!props.userid) return;
        axios.post('https://liveapi247.live/api2/FancyExposure', {
            id: props.userid,
            token: props.token,
            eventId: props.eventId,
            selectionId: props.selection


        }).then(result => {
            setrefresh(false);
            if (result.status === 200) {

                var res = [];
                for (let key in result.data) {

                    if (result.data.hasOwnProperty(key)) {
                        let obj = {};
                        obj.run = key;
                        obj.position = result.data[key];

                        res.push(obj);


                    }
                }
                if (res.length < 7) {
                    res = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '];
                }
                //console.log(res)
                setbookList(res);

            }

        }
        ).catch(e => {
            //setIsError(true);
        });
    }, [])
    return (
        <React.Fragment>

            {refresh === true &&
                <div _ngcontent-njs-c65="" class="loading-overlay" id="loading">
                    <div _ngcontent-njs-c65="" class="loading-wrap" style={{ display: " flex" }}>
                        <div _ngcontent-njs-c65="" class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>}

            <div _ngcontent-njs-c65="" id="fancyBetBookLeftSide" className="overlay" style={{ display: 'flex' }}>
                <div _ngcontent-njs-c65="" id="sideWrap" className="side-wrap full-pop">
                    <title _ngcontent-njs-c65="" >gamex</title>
                    <div _ngcontent-njs-c65="" className="side-head">
                        <h3 _ngcontent-njs-c65="" className>Book Position</h3>
                        <a _ngcontent-njs-c65="" className="close" onClick={() => { props.handleFancyBook(false); }}>Close</a>
                    </div>

                    <div _ngcontent-njs-c65="" id="sideContent" className="side-content">
                        <div _ngcontent-njs-c65="" className="path-wrap">
                            <ul _ngcontent-njs-c65="" >
                                <li _ngcontent-njs-c65="" id="headerName">{props.runner}</li>
                            </ul>
                        </div>
                        {refresh === false &&
                            <table _ngcontent-njs-c65="" id="table" className="table table-fancy">
                                <tbody _ngcontent-njs-c65="" >
                                    <tr _ngcontent-njs-c65="" >
                                        <th _ngcontent-njs-c65="" width="40%">Runs</th>
                                        <th _ngcontent-njs-c65="" width="60%">Amount</th>
                                    </tr>

                                    {bookList.map((item, index) => {
                                        return (
                                            <tr _ngcontent-njs-c65="" id="runsEx" style={{ display: 'table-row' }}>
                                                <td _ngcontent-njs-c65="" id="runs" className="col-back"><strong>{item.run}</strong></td>
                                                <td _ngcontent-njs-c65="" id="exposure" className="col-back"><strong> {item != ' ' ? parseFloat(item.position).toFixed(2) : item.position}</strong></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}