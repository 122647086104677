import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment'
import { AnnounceNews } from './AnnounceNews';
import {useNewsData} from "../serives/react-query/api-hooks";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";

export default function ANnouncement(props) {

    const [openpopup, setopenpopup] = useState(false);

    // get Banner data
    let getNewsData = getRqData(RQ_KEY.STORE_NEWS_DATA);
    // enabled is on mount data get
    const newsDataApi = useNewsData({ id:  props.userid, token:  props.utoken }, { enabled: !getNewsData });

    const handleOpen =(News)=> setopenpopup(News);

    return (
        <React.Fragment>
{openpopup &&
<AnnounceNews handleOpen={handleOpen}  userid = {props.userid} utoken = {props.utoken}/>}

        <div  className="marquee-box" onClick={()=>{props.NewsClick()}}>
	<h4>News</h4>
          <div  className="marquee">
    <div style={{width: "100000px", transform: "translateX(314px)", animation:" 70.105s linear 0s infinite normal none running marqueeAnimation-4429646"}} className="js-marquee-wrapper">
    {(getNewsData && getNewsData.data.length !== 0) && getNewsData.data.map(function(item,id){

     var Date = moment(item.date).format('ll');
    return (
      <div   key={id} className="js-marquee" style={{marginRight:' 0px', float:' left'}} onClick={()=>{handleOpen(true)}}><a>
        <span>{Date}</span>
       {item.message}
        </a></div>
             )})}
        </div>
    </div>
</div>
        </React.Fragment>
    )
}
