import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import Horseracing from '../images/casino/horceracing.png'
import Colorgame from '../images/casinogif/casinopng/Color_Game.png'
import card32 from '../images/casinogif/casinew/cards_32.png'
import teenipatti from '../images/casino/teenpatti20.webp';
import livecasino from '../images/casinowebp/livecasino.webp'
import Heistimg from '../images/casinogif/casinew/heist.png'
import VenusCasino from '../images/casino/venuscasino.webp'
import updown from '../images/casinogif/casinopng/7_Up_7_Down.png'
import Evolution from '../images/casino/evolution-king.webp'
import SABA from '../images/casino/saba-sport.webp'
import anderbahar from '../images/casinogif/casinopng/Andar_Bahar.png'
import CoinToss from '../images/casinogif/casinopng/Coin_Toss.png'
import aviator from '../images/casino/banner_sbe_aviator.png'
import e1sports from '../images/casinowebp/esports.webp'
import jetx3 from '../images/casino/banner_jetx.png'
import jetx from '../images/casino/banner_Aviator-jetx.png'
import blackjack from '../images/casinogif/casinew/blackjack.jpeg'
import cricketx from '../images/casino/banner_cricketx.png'
import numbermatka from '../images/casinogif/casinew/numbermatka.png'
import Sports from '../images/Banner/bannersports.webp'
import cardmatka from '../images/casinowebp/cardmatka.webp'
import mineswiper from '../images/casino/mineswiper.webp'
import { Link } from 'react-router-dom';
import Support from './Support';
import sibco from '../images/casinowebp/kingsicbo.webp'
import KingMakers from '../images/casinogif/casinew/kingmaker.png'
import upsevendown from '../images/casinogif/casinew/UpDown.jpeg'
import TeenpattiRoyal from '../images/casinogif/sexybacarate.webp'
import cockfight from '../images/casino/cockfight.png'
import roulette from '../images/casinogif/casinew/European_Roulette.webp'
import cardhilo from '../images/casinogif/casinew/Cards_Hi_Lo.png'
import NumberKing from '../images/casinogif/casinew/number-matka.png'
import JilI from '../images/casino/banner_JILI-full.webp'
import BigSmall from '../images/casino/big-small.webp'
import Teenpattijoker from '../images/casino/teenpatti.webp'
import updownseven from '../images/casino/7updown.webp'
import DragerTiger from '../images/casino/dragon-tiger.jpeg'
import CallBreak from '../images/casinogif/casinew/Callbreak.png'
import SicboJili from '../images/casinogif/casinew/sicbo-half.png'
import BaccaratJili from '../images/casino/baccaret.webp'
import andarbaharjili from '../images/casinogif/Andar_Bahar.webp'
import ak47 from '../images/casinogif/casinew/AK47.png'
import royalfish from '../images/casinogif/casinew/Royal_Fishing.png'
import jackpotfish from '../images/casinogif/casinew/Jackpot_Fishing.png'
import megafish from '../images/casinogif/casinew/Mego-Fishing.png'
import starfish from '../images/casinogif/casinew/All_star_Fishing.png'
import happyfish from '../images/casinogif/casinew/Happy_Fishing.png'
import dragonfor from '../images/casinogif/casinew/dragon-for.png'
import Playtech from '../images/casino/Playtech-Casino.webp'
import Redtiger from '../images/casino/redtiger.webp'
import { getCasinoObj } from '../casino-utils';
import queryString from 'query-string';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default function Home(props) {

    const [countCricket, setCountCricket] = useState(0);
    const [countSoccer, setCountSoccer] = useState(0);
    const [countTennis, setCountTennis] = useState(0);
    const [countBasketBall, setCountBasketBall] = useState(0);
    const [loading, setloading] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [casinoWait, setcasinoWait] = useState(false);

    const history = useHistory();


    function changecount(cri, socc, tenn, basket) {

        setCountCricket(cri);
        setCountSoccer(socc);
        setCountTennis(tenn);
        setCountBasketBall(basket)
    }


    useEffect(() => {
        axios.all([
            axios.get('https://liveapi247.live/api8/cricketData'),
            axios.get('https://liveapi247.live/api8/soccerData'),
            axios.get('https://liveapi247.live/api8/tennisData'),
            axios.get('https://liveapi247.live/api8/basketballData')
        ])
            .then(axios.spread((obj1, obj2, obj3, obj4) => {


                var count1 = 0;
                var count2 = 0;
                var count3 = 0;
                var count4 = 0;

                obj1.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj2.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj3.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })
                obj4.data.map(item => {
                    item.marketStartTime = moment.tz(item.marketStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                    return item;
                })

                obj1.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count1 = count1 + 1;
                    }
                });
                obj2.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count2 = count2 + 1;
                    }
                });
                obj3.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count3 = count3 + 1;
                    }
                });
                obj4.data.map((item) => {
                    if (moment() >= moment(item.marketStartTime)) {
                        count4 = count4 + 1;
                    }
                });
                setrefresh(false);
                setTimeout(() => { setloading(false); }, 5000);
                changecount(count1, count2, count3, count4);



            }));
    }, [])

    const handleRefresh = () => {
        setloading(true);
    }

    return (
        <React.Fragment>

            {props.casinoWait &&
                <div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
                    <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
                        <div _ngcontent-njs-c67="" class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }







            {refresh === true &&
                <div _ngcontent-njs-c67="" class="loading-overlay" id="loading">
                    <div _ngcontent-njs-c67="" class="loading-wrap" style={{ display: " flex" }}>
                        <div _ngcontent-njs-c67="" class="loading">
                            <div></div>
                            <div></div>
                        </div>
                        <p>Loading...</p>
                    </div>
                </div>
            }


            <div _ngcontent-njs-c67="" class="mob-main-content bg-half">
                <div _ngcontent-njs-c67="" class="casinozone">
                    <Link _ngcontent-njs-c67="" to="/d/dash/inplay" style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Sports</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <dl _ngcontent-njs-c67="" id="onLiveBoard" class="live_events">
                            <dt>
                                <p class="live_icon"><span></span> LIVE</p>
                            </dt>

                            <dd _ngcontent-njs-c67="" id="onLiveCount_CRICKET"><p>Cricket</p><span _ngcontent-njs-c67="" id="count">{countCricket}</span></dd>

                            <dd _ngcontent-njs-c67="" id="onLiveCount_SOCCER"><p>Soccer</p><span _ngcontent-njs-c67="" id="count">{countSoccer}</span></dd>

                            <dd _ngcontent-njs-c67="" id="onLiveCount_TENNIS"><p>Tennis</p><span _ngcontent-njs-c67="" id="count">{countTennis}</span></dd>
                            {/* <dd _ngcontent-njs-c67="" id="onLiveCount_TENNIS"><p>Basketball</p><span _ngcontent-njs-c67="" id="count">{countBasketBall}</span></dd>
                            <dd _ngcontent-njs-c67="" id="onLiveCount_TENNIS"><p>Election</p><span _ngcontent-njs-c67="" id="count">{1}</span></dd> */}

                        </dl>
                        <img _ngcontent-njs-c67="" src={Sports} alt="" draggable="false" />
                    </Link>
                    {/*  */}
                    <a _ngcontent-njs-c67=""
                        // style={{ gridColumn: 'span 2' }}
                        class="mob-casino-half" onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Aviator'))}`) : props.openLogin()}

                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Aviator</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={aviator} alt="" draggable="false" />
                    </a>
                    {/*  */}
                    <a _ngcontent-njs-c67=""
                        // style={{ gridColumn: 'span 2' }}
                        class="mob-casino-half" onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('JetX'))}`) : props.openLogin()}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Jetx</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={jetx} alt="" draggable="false" />
                    </a>
                    {/*  */}
                    <a _ngcontent-njs-c67=""
                        // style={{ gridColumn: 'span 2' }}
                        class="mob-casino-half" onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('JetX3'))}`) : props.openLogin()}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Jetx3</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={jetx3} alt="" draggable="false" />
                    </a>
                    {/*  */}
                    <a _ngcontent-njs-c67=""
                        // style={{ gridColumn: 'span 2' }}
                        class="mob-casino-half" onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('CricketX'))}`) : props.openLogin()}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>CricketX</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={cricketx} alt="" draggable="false" />
                    </a>
                    {/*  */}
                    <a _ngcontent-njs-c67=""
                        style={{ gridColumn: 'span 2' }}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('SABA'))}`) : props.openLogin()}

                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Saba</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={SABA} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        style={{ gridColumn: 'span 2' }}
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('E1SPORT'))}`) : props.openLogin()}

                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>E1SPORTS</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='img-bg-sports' src={e1sports} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""

                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Baccarat Classic'))}`) : props.openLogin()}
                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Sexy</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='img-bg' src={TeenpattiRoyal} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""

                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Live Casino'))}`) : props.openLogin()}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Live Casino</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={livecasino} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""

                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('EVOLUTION'))}`) : props.openLogin()}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Evolution</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={Evolution} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""

                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('playtech'))}`) : props.openLogin()}
                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Ezugy</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={Playtech} alt="" draggable="false" />
                    </a>
                    {/* <a _ngcontent-njs-c67=""

                    // onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('EVOLUTION'))}`) : props.openLogin()}
                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Red Tiger</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={redtiger} alt="" draggable="false" />
                    </a> */}


                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('KINGMAKER'))}`) : props.openLogin()}
                        style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>KingMakers</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='king-img' src={KingMakers} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Redtiger'))}`) : props.openLogin()}
                        style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Red Tiger</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='king-img' src={Redtiger} alt="" draggable="false" />
                    </a>

                    {/* <a _ngcontent-njs-c67=""
                        // onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('LUDO'))}`) : props.openLogin()}
                        style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Ludo</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={Ludocasino} alt="" draggable="false" />
                    </a> */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('VENUS'))}`) : props.openLogin()}
                        class="mob-casino-half"
                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Venus</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={VenusCasino} alt="" draggable="false" />
                    </a>

                    {/* lobby_kingmakers.webp */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('sicbo'))}`) : props.openLogin()}
                        class="mob-casino-half"
                    >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>SicBo</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='bg-scbo' src={sibco} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Heist'))}`) : props.openLogin()}
                        class="mob-casino-half">
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Heist</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='Heist-img' src={Heistimg} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('7 Up 7 Down'))}`) : props.openLogin()}
                        class="mob-casino-half">
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>7 up 7 down</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='updown-img' src={updown} alt="" draggable="false" />
                    </a>
                    {/* onClick = {()=>{openEvolution(6511);}} */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Coin Toss'))}`) : props.openLogin()}
                        class="mob-casino-half">
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Coin Toss</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='coin-img' src={CoinToss} alt="" draggable="false" />
                    </a>


                    <a _ngcontent-njs-c67=""
                        class="mob-casino-half bg-half"
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('NumberMatka'))}`) : props.openLogin()}
                        style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Number Matka</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='number-bg' src={numbermatka} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('CardMatka'))}`) : props.openLogin()}
                        class="mob-casino-half" >
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Card Matka</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={cardmatka} alt="" draggable="false" />
                    </a>
                    {/* onClick = {()=>{openEvolution(2342);}} */}
                    {/* <a  _ngcontent-njs-c67=""   class="entrance-hal
    f">
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Ebets</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={Ebets} alt="" draggable="false"/>
    </a> */}


                    {/* <li  _ngcontent-njs-c67="" nk to="/baccarat" class="mob-casino-half"  style={{cursor: "pointer"}}>
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Baccarat</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={Baccarat} alt="" draggable="false"/>
    </Link> */}
                    {/* <li  _ngcontent-njs-c67="" nk to="/sicbo" class="mob-casino-half"  style={{cursor: "pointer"}}>
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Sicbo</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={Sicbo} alt="" draggable="false"/>
    </Link> */}

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Andar Bahar'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Andar Bahar</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" class="bg-img-evo" src={anderbahar} alt="" draggable="false" />
                    </a>

                    {/* onClick = {()=>{openEzugi('poker');}} */}

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Blackjack'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Blackjack</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='blackjack-img' src={blackjack} alt="" draggable="false" />
                    </a>

                    {/* onClick={() => props.handleCasinoBal('EZ','LC', 'Ezugi', props.EZBalance)} */}



                    {/* <a  _ngcontent-njs-c67=""    onClick = {()=>{openAESexy(600009);}} class="mob-casino-half">
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Jili</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={Poker} alt="" draggable="false"/>
    </a> */}

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Cards Hi Lo'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Card Hilo</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={cardhilo} alt="" draggable="false" />
                    </a>
                    {/* <a  _ngcontent-njs-c67=""   onClick = {()=>{openEvolution(12339);}} style={{gridColumn: 'span 2 / auto'}}class="entrance">
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Microgaming</dt>
            <dd><span>Play Now</span></dd>
        </dl>
          <img  _ngcontent-njs-c67="" src={microgaming} alt="" draggable="false"/>
      
    </a> */}
                    {/* <a  _ngcontent-njs-c67=""  class="entrance" style={{gridColumn: 'span 2 / auto'}} neua="Binary Banner">
    <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>European Roulette</dt>
            <dd><span>Play Now</span></dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={europianR} alt="" draggable="false"/>
    </a> */}


                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Minesweeper'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Mine Sweeper</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={mineswiper} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Colour Game'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Color Game</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='color-img' src={Colorgame} alt="" draggable="false" />
                    </a>

                    {/* <!-- KM_7up7down --> */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('European Roulette'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Roulette</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={roulette} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('32 Cards'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>32 Cards</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={card32} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('JILI'))}`) : props.openLogin()}
                        style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" className="casino-title">
                            <dt>Jili</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={JilI} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Teen Patti'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>TeenPatti</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={teenipatti} alt="" draggable="false" />
                    </a>

                    {/* <!-- BetGames --> */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('NumberKing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Number King</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={NumberKing} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Big small'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Big Small</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={BigSmall} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('TeenPatti Joker'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Teenpatti Joker</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={Teenpattijoker} alt="" draggable="false" />
                    </a>


                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('7up7down'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>7Up7Down</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={updownseven} alt="" draggable="false" />
                    </a>


                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Dragon & Tiger'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Dragon & Tiger</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={DragerTiger} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Callbreak Quick'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Callbreak Quick</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={CallBreak} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Sic Bo'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Sic Bo</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={SicboJili} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push('/game-play/JJ/SL/Bacarrat/152') : props.openLogin()}
                        // onClick={() => props.handleCasinoBal('JL','SL', 'Bacarrat', props.JLBalance,'152')} 
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Bacarrat</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={BaccaratJili} alt="" draggable="false" />
                    </a>
                    {/* new add */}
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('7 Up 7 Down'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>7 Up 7 Down</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={upsevendown} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Andar Bahar'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Andar Bahar</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={andarbaharjili} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('AK47'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>AK47</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={ak47} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Royal Fishing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Royal Fishing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={royalfish} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Jackpot Fishing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Jackpot Fishing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={jackpotfish} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Mega Fishing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Mega Fishing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='mega-twenti' src={megafish} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('All-star Fishing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>All Star Fishing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={starfish} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Happy Fishing'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Happy Fishing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={happyfish} alt="" draggable="false" />
                    </a>
                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Dragon Fortune'))}`) : props.openLogin()}
                        class="mob-casino-half" style={{ cursor: "pointer" }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Dragon Fortune</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" className='dragon-img' src={dragonfor} alt="" draggable="false" />
                    </a>


                    {/* <!-- KM Andar Bahar --> */}

                    {/* onClick = {()=>{openEzugi('32cards');}} */}



                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('HORSE BOOK'))}`) : props.openLogin()}
                        style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>horse Racing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={Horseracing} alt="" draggable="false" />
                    </a>

                    <a _ngcontent-njs-c67=""
                        onClick={() => props.isLoggedIn ? history.push(`/game-play/casino?${queryString.stringify(getCasinoObj('Cock Fight PHI'))}`) : props.openLogin()} style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Cock Fight</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={cockfight} alt="" draggable="false" />
                    </a>




                    {/* <!-- KM Jhandi Munda --> */}


                    {/* <a _ngcontent-njs-c67="" style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>Horse Raccing</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={horseraccing} alt="" draggable="false" />
                    </a> */}

                    {/* <a _ngcontent-njs-c67="" style={{ gridColumn: 'span 2' }}>
                        <dl _ngcontent-njs-c67="" class="casino-title">
                            <dt>kabbaddi</dt>
                            <dd><span>Play Now</span></dd>
                        </dl>
                        <img _ngcontent-njs-c67="" src={kabbaddi} alt="" draggable="false" />
                    </a> */}


                    {/**/}
                    {/* <a  _ngcontent-njs-c67=""  class="entrance" style={{gridColumn: 'span 2 / auto'}} neua="Binary Banner">
        <dl  _ngcontent-njs-c67="" class="casino-title">
            <dt>Binary</dt>
            <dd>Comming Soon</dd>
        </dl>
        <img  _ngcontent-njs-c67="" src={binary} alt="" draggable="false"/>
    </a> */}


                </div>

                <Support handlepolicy={props.handlepolicy} />
            </div>

        </React.Fragment >
    )
}
