import React, { useState } from 'react'
import Cup from '../images/sprite/cup.gif'
import OpenBets from './openBets'

export default function LeftSide(props) {


  return (
    <div>

      <div _ngcontent-njs-c67="" id="openBetsLeftSide" className="overlay left-side" >
        <div _ngcontent-njs-c67="" id="openBetSlide" className="side-wrap">
          <div _ngcontent-njs-c67="" id="sideHead" className="side-head">
            <h3 _ngcontent-njs-c67="" className="mob-bet-show">
              <img src={Cup} alt="mob-bet-show" />Open Bets</h3>
            <a _ngcontent-njs-c67="" onClick={props.handleleftsideclose} className="close">.</a>
          </div>
          <div _ngcontent-njs-c67="" className="side-content">

            <OpenBets utoken={props.utoken} userid={props.userid} />

          </div>
        </div>
      </div>
    </div>

  )
}
