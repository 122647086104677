import React, { useEffect, useState, memo} from "react";


const CaptchaGenerator = ({ captchaCode, spanClass, className, bgColor, textColor}) => {
    const [randomForDigit, setRandomForDigit] = useState();
    // const borderStyle = border ? { borderColor: generalColors.primary } : {border: 0};
    
    useEffect(() => {
        // setForDigit
        setRandomForDigit(Math.floor(1000 + Math.random() * 9000))
    }, [])
    // send for digit to parent
    useEffect(() => {
        captchaCode(randomForDigit);
    }, [randomForDigit])

    return (
     <div 
     style={{
        backgroundColor: bgColor,
        color: textColor,
        width: 'fit-content'
     }}
     className={`${className || ''} px-2 py-2`}
     >
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold'}} className={`${spanClass || ''}`}>
        {randomForDigit && randomForDigit}
      </span>
     </div>
    );
  };

export default memo(CaptchaGenerator);