import React, { useState } from 'react'
import Transparent from '../images/sprite/transparent.gif'
import Loading from "../images/sprite/loading40.gif"
import Home from '../desktopcomponent/Home'
import Cricket from './Cricket'
import Tennis from './Tennis'
import Soccer from './Soccer'
import Inplay from './InplayDesktop'
import Multimarketdesk from './Multimarketdesk'
import Oneclickbet from './Oneclickbet'
import News from './News'
import NewFullmarket from './NewFullmarket'
import Result from './Result'
import WinnerMarket from './WinnerMarket'
import Basketball from './Basketball'
import Election from './Election'



export default function Center(props) {

  const [Socialicon, setSocialicon] = useState(0);

  return (


    <React.Fragment>
      {/* <div _ngcontent-lst-c69="" id="centerColumn" class="centar-content1" style={{}}>
        {props.tabmenu === 2 && <News userid={props.userid} utoken={props.utoken} isLoggedIn={props.isLoggedIn} />}
        <div _ngcontent-lst-c69="" id="overWrap" class="lit-content-center" style={props.isLoggedIn ? props.oneclick ? { height: "calc(100% - 55px)" } : { height: "calc(100% - 26px)" } : { height: "calc(100% - 0px)" }}>
       
        </div>
      </div> */}
      <div id="centerColumn" className={`centar-content ${(props.tabmenu === 2) ? "inplay" : ""}`} style={(props.tabmenu === 2) ? { marginRight: '27.0417%', paddingTop: '0px' } : {}}>



        <News userid={props.userid} utoken={props.utoken} isLoggedIn={props.isLoggedIn} />


        <div _ngcontent-lst-c69="" id="message" class="message-content success">
          <a _ngcontent-lst-c69="" class="close-button">Close</a>
          <p></p>
        </div>

        {props.tabmenu === 0 && <NewFullmarket betType={props.betType} oneclick={props.oneclick} changeWaiting={props.changeWaiting} oneClickMarket={props.oneClickMarket} updateBalance={props.updateBalance} anyBets={props.anyBets} isanybets={props.isanybets} dStake={props.dStake} team={props.team} betClick={props.betClick} odds={props.odds} handleOpen={props.handleOpen} onSelect={props.onSelect} setOnClick={props.setOnClick} showBetForm={props.showBetForm} EventId={props.EventId} MarketId={props.MarketId} EventT={props.EventT} isLoggedIn={props.isLoggedIn} oneclick={props.oneclick} userid={props.userid} utoken={props.utoken}

        />}

        {props.tabmenu === 30 && <WinnerMarket betType={props.betType} oneclick={props.oneclick} changeWaiting={props.changeWaiting} oneClickMarket={props.oneClickMarket} updateBalance={props.updateBalance} anyBets={props.anyBets} isanybets={props.isanybets} dStake={props.dStake} team={props.team} betClick={props.betClick} odds={props.odds} handleOpen={props.handleOpen} onSelect={props.onSelect} setOnClick={props.setOnClick} showBetForm={props.showBetForm} EventId={props.EventId} MarketId={props.MarketId} EventT={props.EventT} isLoggedIn={props.isLoggedIn} oneclick={props.oneclick} userid={props.userid} utoken={props.utoken}

        />}
        {props.tabmenu > 0 &&

          <div _ngcontent-lst-c69="" id="overWrap" class="lit-content-center" style={props.isLoggedIn ? props.oneclick ? { height: "calc(100% - 55px)" } : { height: "calc(100% - 26px)" } : { height: "calc(100% - 0px)" }}>

            {props.tabmenu === 2 && <Inplay changeState={props.changeState} />}
            {props.tabmenu === 4 && <Cricket changeState={props.changeState} />}
            {props.tabmenu === 5 && <Soccer changeState={props.changeState} />}
            {props.tabmenu === 6 && <Tennis changeState={props.changeState} />}
            {props.tabmenu === 17 && <Basketball changeState={props.changeState} />}
            {props.tabmenu === 3 && <Multimarketdesk changeState={props.changeState} />}
            {props.tabmenu === 31 && <Election changeState={props.changeState} />}


            {props.tabmenu !== 2 && <div class="footer" >

              <div id="supportWrap" className="support-wrap">
                <div className="support-service">
                  <a id="support_email" onMouseOver={() => { setSocialicon(0) }} className={`support-mail  ${(Socialicon === 0) ? "open" : "null"}`} >
                    <img src={Transparent} title="Email" /></a>
                  <a id="support_whatsapp" onMouseOver={() => { setSocialicon(1) }} className={`support-whatsapp  ${(Socialicon === 1) ? "open" : "null"}`} >
                    <img src={Transparent} title="WhatsApp" /></a>
                  <a
                    id="support_telegram" onMouseOver={() => { setSocialicon(2) }} className={`support-telegram  ${(Socialicon === 2) ? "open" : "null"}`} >
                    <img src={Transparent} title="Telegram" /></a>
                  <a id="support_email" onMouseOver={() => { setSocialicon(3) }} className={`support-skype  ${(Socialicon === 3) ? "open" : "null"}`} >
                    <img src={Transparent} title="skype" /></a>
                  <a
                    id="support_email" onMouseOver={() => { setSocialicon(4) }} className={`support-ig  ${(Socialicon === 4) ? "open" : "null"}`} >
                    <img src={Transparent} title="skype" /></a>
                </div>
                <div className="support-info">
                  <div id="supportDetail_telegram" className="support-detail open">
                    <a className="ui-link">
                      <span style={{ color: 'rgb(102, 102, 102)', textDecoration: 'underline' }} /></a>
                  </div>
                </div>
              </div>

              <div _ngcontent-lst-c69="" class="power-content" style={{ width: "109px" }}>
                <h3 _ngcontent-lst-c69="" style={{ backgroundImage: 'none', lineHeight: '15px', color: '#484745', marginTop: '-5px', textAlign: 'left' }}><span>Powered by</span><img src={Transparent} alt="poweredby" /></h3>
              </div>
              <div _ngcontent-lst-c69="" class="browser-footer-content">

                <img src={Transparent} alt="gif" />
                <br></br>
                Our website works best in the newest and last prior version of these browsers: <br></br>Google Chrome.
              </div>


              <ul _ngcontent-lst-c69="" class="mob-inform-link">
                <li _ngcontent-lst-c69=""   ><a >KYC</a></li>
                <li _ngcontent-lst-c69=""   ><a >About Us</a></li>
                <li _ngcontent-lst-c69=""   ><a >Privacy Policy</a></li>
                <li _ngcontent-lst-c69=""   ><a >Responsible Gaming</a></li>
                <li _ngcontent-lst-c69=""   ><a >Terms and Conditions</a></li>
                <li _ngcontent-lst-c69=""   ><a >Underage Gaming Policy</a></li>
                <li _ngcontent-lst-c69=""   ><a >Self-Exclusion Policy</a></li>
                <li _ngcontent-lst-c69=""   ><a >Rules and Regulations</a></li>
              </ul>

              <ul _ngcontent-lst-c69="" class="RnR-link">
                <li><a _ngcontent-lst-c69=""   >Rules and Regulations</a></li>
              </ul>
            </div>}
          </div>}
        {props.oneclick === true && <Oneclickbet userid={props.userid} utoken={props.utoken} />}
      </div>
    </React.Fragment>


  )
}
